import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import SingleQuestionComponent from "../../components/questions/SingleQuestion";
import SpinnerLoader from "../../components/common/loader/SpinnerLoader";

import {
	getSingleReview,
	updateReview,
	changeReviewStatus,
} from "../../services/ReviewsServices";
import SingleReviewComponent from "../../components/questions/SingleReview";

const SingleReview = () => {
	const [review, getReview] = useState({});
	const [loading, setLoading] = useState(true);
	const [isEdit, setIsEdit] = useState(false);
	const { reviewId } = useParams();
	const history = useNavigate();

	useEffect(() => {
		if (reviewId) {
			getSingleReview(reviewId)
				.then((res) => getReview(res.data))
				.catch((err) => {
					console.log(err);
				})
				.finally((res) => setLoading(false));
		}
	}, [reviewId]);

	const navLinks = [
		{
			name: "Unread",
			link: "/",
		},
		{
			name: "All",
			link: "/reviews?type=all",
		},
		{
			name: "Published",
			link: "/reviews?type=published",
		},
		{
			name: "Unpublished",
			link: "/reviews?type=unpublished",
		},
		{
			name: "Archived",
			link: "/reviews?type=archived",
		},
	];

	const handleEdit = () => {
		setIsEdit(!isEdit);
	};

	const handleReviewApprove = (status) => {
		setLoading(true);
		changeReviewStatus({ review_id: [reviewId], status })
			.then((res) => history(-1))
			.catch()
			.finally((res) => {
				setLoading(false);
			});
	};

	const handleArchive = (status) => {
		
		setLoading(true);
		changeReviewStatus({ review_id: [reviewId], status })
			.then((res) => history(-1))
			.catch()
			.finally((res) => {
				setLoading(false);
			});
	};

	const handleUpdateReview = (review, rating) => {
		const reviewData = {
			review,
			rating,
		};
		updateReview(reviewId, reviewData)
			.then((res) => {
				if (res?.data?.status === false) {
					setIsEdit(true);
				} else {
					setIsEdit(false);
				}
			})
			.catch((err) => console.log(err));
	};

	return (
		<div>
			{loading ? (
				<div className="flex justify-center items-center w-full h-full">
					<SpinnerLoader />
				</div>
			) : (
				<SingleReviewComponent
					navLinks={navLinks}
					handleApprove={handleReviewApprove}
					heading="Product Review"
					data={review}
					isEdit={isEdit}
					handleEdit={handleEdit}
					handleSubmit={handleUpdateReview}
					handleArchive={handleArchive}
				/>
			)}
		</div>
	);
};
export default SingleReview;
