import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import Rating from "react-rating";
import { StarIcon as FilledStar } from "@heroicons/react/solid";

import PrimaryHeading from "../common/headings/PrimaryHeading";
import PrimaryButton from "../common/buttons/PrimaryButton";
import ReplyQuestion from "../reviews/ReplaySection";
import ActionNav from "../common/navbar/ActionNav";
import ProductRatingCard from "../product/ProductRatingCard";
import { alphabetColors } from "../../utils/string";
import toast, { Toaster } from "react-hot-toast";
import { createAnswer } from "../../services/QuestionsServices";

function SingleQuestionComponent({
	heading,
	data,
	handleApprove,
	navLinks,
	handleSubmit,
	isEdit,
	handleEdit,
	handleArchive,
	question_id,
}) {
	const [isReply, setReply] = useState(false);
	const [sendReply, setSendReply] = useState(false);
	const [ratingData, getRatingData] = useState({});
	const [rating, setRating] = useState(4);
	const [description, setDescription] = useState("description");
	const [replyText, setReplyText] = useState({ text: "", html: "" });
	const [responseText, setResponseText] = useState("");

	useEffect(() => {
		if (data?.answer) {
			setReplyText({ text: data?.answer, html: data?.answer });
			setResponseText({ text: data?.answer, html: data?.answer });
		}
	}, []);

	const handleReply = () => {
		setReply(true);
	};

	const handleChange = (newContent) => {
		setReplyText({
			html: newContent, // Use newContent directly
			text: newContent, // Assuming you want to store both HTML and plain text versions
		});
	};

	const handleSentReplay = () => {
		setReply(false);
		if (replyText?.text?.trim?.()) {
			createAnswer(question_id, replyText?.html)
				.then((res) => {
					setResponseText({html:replyText?.text, text:replyText?.text});
					setSendReply(true);

					toast.success("Answer Added successfully!");
				})
				.catch((err) => {
					console.log(err,"check error");
					if(err.response.status != 401){
						toast.error(JSON.stringify(err));
					}
					
				});
		} else {
			toast.error("Reply Text cannot be empty");
			setReply(true);
		}
	};

	const handleDiscard = () => {
		// setDescription(data?.review ? data?.review?.review : data?.question);
		handleEdit();
	};

	const handleReviewRating = (rate) => {
		setRating(rate);
	};

	const handleEditAnswer = () => {
		setReply(true);
	};

	const formattedDate = moment(
		data?.created_at,
		"YYYY-MM-DD HH:mm:ss"
	).format("MMMM DD, YYYY hh:mm A");

	return (
		<div className="single-question-container">
			<Toaster position="top-center" reverseOrder={false} />
			<PrimaryHeading heading="Questions" />
			<div className="bg-white mt-4 rounded body-shadow">
				<div className="border-b border-[#BDBDBD]">
					<div className="mt-6">
						<ActionNav
							title={heading}
							archiveStatus={4}
							publishedStatus={1}
							status={data?.status}
							handleArchive={() => handleArchive(4)}
							date={formattedDate}
							handleApprove={() => handleApprove(1)}
							path={"question"}
						/>
					</div>
				</div>
				<div className="p-6 border-b border-[#BDBDBD] flex justify-between flex-col lg:flex-row">
					<div className="flex w-[90%] mb-4 lg:mb-0 pr-2">
						<div
							className="h-[43px] w-[43px] min-w-[43px] flex items-center justify-center rounded-full mix-blend-difference font-semibold"
							style={{
								background:
									alphabetColors?.[
										data?.customer?.first_name?.[0]?.toLowerCase?.()
									],
							}}
						>
							{data?.customer?.first_name?.[0]?.toUpperCase?.()}
						</div>
						<div className="ml-2.5">
							<div>
								<h3 className="text-[15px] font-medium">
									<span className="capitalize">
										{data?.customer?.first_name}
									</span>{" "}
									<span className="capitalize">
										{data?.customer?.last_name}
									</span>
								</h3>
								<p className="text-sm">
									<span className="text-[#828282]">
										{data?.customer?.email}
									</span>
								</p>
							</div>
							{data?.rating && (
								<div>
									<div
										className={`rating-stars-innerwrapper flex items-center `}
									>
										<Rating
											initialRating={data?.rating}
											readonly={!isEdit}
											onClick={handleReviewRating}
											emptySymbol={
												<div className="w-7 h-7">
													<FilledStar color="#BDBDBD" />
												</div>
											}
											fullSymbol={
												<div className="w-7 h-7">
													<FilledStar color="#FFC200" />
												</div>
											}
										/>
									</div>
								</div>
							)}

							<div className="mt-3">
								{/* <p className="text-[15px] font-medium">
									{data?.product?.title}
								</p> */}
								{isEdit ? (
									<div className="mt-2">
										<input
											type="text"
											className="border border-[#BDBDBD] rounded p-3.5"
											value={"description"}
											onChange={(e) =>
												setDescription(e.target.value)
											}
										/>
										<div className="flex mt-2">
											<button
												type="reset"
												value="Reset"
												className="px-4 rounded py-1 text-[#60579E] border border-[#60579E] flex items-center justify-center md:mr-6"
												onClick={handleDiscard}
											>
												Discard
											</button>
											<button
												className="px-4 rounded py-1 bg-[#60579E] text-white flex items-center justify-center"
												onClick={() =>
													handleSubmit(
														description,
														rating && rating
													)
												}
											>
												save
											</button>
										</div>
									</div>
								) : (
									<p className="text-[#4F4F4F] text-[15px] break-words break-all">
										{data?.question}
									</p>
								)}
							</div>
						</div>
					</div>
					<ProductRatingCard item={ratingData} data={data} />
				</div>
				<div className="p-6">
					<div className="w-full">
						{isReply ? (
							<div>
								<ReplyQuestion
									customer={data.customer}
									handleSentReplay={handleSentReplay}
									handleChange={handleChange}
									value={replyText}
									handleCancelSent={() => setReply(false)}
								/>
							</div>
						) : responseText?.text?.trim?.() ? (
							<div className="flex items-start justify-between w-full">
								<div>
									<h4 className="font-medium text-lg">
										Reply:
									</h4>
									<div
										className="prose"
										dangerouslySetInnerHTML={{
											__html: responseText?.html,
										}}
									></div>
								</div>
								<div>
									<PrimaryButton
										handleClick={handleEditAnswer}
										position="prepend"
										icon="/images/icons/edit.svg"
										styling="rounded w-max py-[6px] px-2.5 bg-transparent border border-[#BDBDBD] text-[13px]  rounded  text-black flex items-center justify-center"
										content="Edit"
										iconClassName="h-[14px] w-[14px]"
										textStyle="ml-[5px] !text-[13px] !leading-[14px]"
									/>
								</div>
							</div>
						) : (
							<PrimaryButton
								handleClick={handleReply}
								position="prepend"
								icon="/images/icons/share.svg"
								styling="w-[165px]  rounded py-3 bg-transparent border border-[#BDBDBD] rounded  text-black flex items-center justify-center mr-6"
								content="Reply"
							/>
						)}
					</div>
				</div> 
			</div>
		</div>
	);
}

export default SingleQuestionComponent;
