import axiosInstance from "./axiosInstance";
import { ltrim, rtrim } from "../utils/string";
import { siteApiUrl } from "../config";

export const getHttpHeaders = () => {
	return {
		Accept: "application/json",
		"Content-Type": "application/json",
	};
};

export const getToken = () => {
	try {
		const token = localStorage?.getItem("token");
		return token ? JSON.parse(token) : null; // Parse only if there's a token
	} catch (error) {
		console.error("Error parsing token:", error);
		return null;
	}
};

export const getApi = ({ url, data = {}, headers = {}, options }) => {
	const requestUrl = rtrim(siteApiUrl, "/") + "/" + ltrim(url, "/");
	const _headers = {
		...getHttpHeaders(),
		...headers,
		Authorization: `Bearer ${getToken()}`,
	};
	if (Object.keys(data).length > 0) {
		return axiosInstance({
			method: "GET",
			url: requestUrl,
			params: data,
			headers: _headers,
			...options,
		});
	}
	return axiosInstance.get(requestUrl, {
		headers: _headers,
	});
};
